/*
 * A file for Custom mixins and placeholders
 */

// Responsive Mixin

// NOTE: xs doesn't need a media query - mobile styles are the default

// Usage example: @include respond(xl) {}
// Usage example: @include respond(md, max) {}
// Usage example: @include respond(print) {}

// _mixins.scss

$respond-size: null;
$respond-min: null;
$respond-max: null;

@mixin respond($media, $maxmin:'min', $print:'') {

	// check if this is a standard breakpoint or a custom media query
	@if map-has-key($breakpoints, $media) {

		// get the current breakpoint size from the breakpoint map.
		// remove enough from the max breakpoint that it won't clash with the 'min' version
		@if $maxmin=='max' {
			$respond-size: map-get($breakpoints, $media) - 0.01 !global;
		} @else {
			$respond-size: map-get($breakpoints, $media) !global;
		}

		// we don't need a media query for the base style,
		// but catering for it makes other code more straight forward
		@if $media=='base' {
			@content;
		}
        @else if $media == print {
            @media print { @content; }
        }
        @else {
			// render the breakpoint.
			@media screen and (#{$maxmin}-width: #{$respond-size}) {
				@content;
			}
		}

	} @else {
		// render any custom breakpoints, including print style sheet media queries.
		@media #{$media} { @content; }
	}

    @if $print {
        @media print { @content; }
    }

}

// Responsive between breakpoints media queries

// Usage example: @include respond(lg, xl) {}
// Usage example: @include respond(base, md) {}

@mixin respond-between($mediamin, $mediamax) {
    // check if this is a standard breakpoint
    @if map-has-key($breakpoints, $mediamin) and map-has-key($breakpoints, $mediamax) {
        $respond-min: map-get($breakpoints, $mediamin) !global;
        $respond-max: map-get($breakpoints, $mediamax) - 0.01 !global;

        // render the breakpoint.
        @media screen and (min-width: $respond-min) and (max-width: $respond-max) {
            @content;
        }
    }
}
