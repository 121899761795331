/*
Global Variables
Contents:

1.  Imports
2.  Colour variables
3.  Environment variables
4.  Theme colours
5.  Radii and other global css3 style properties
6.  Link colours
7.  Tables
8.  Max page widths applied to container elements
*/

/*
1.  Imports
*/

@import "var-breakpoints";

/*
2.  Colour variables
Put the colours from the design here. When naming your colours
be sure to use  http://chir.ag/projects/name-that-color to get
a proper colour name
*/

/* Primary */
$color-deep-cerulean: #0077AF;
$color-cerulean: #00A7E1;
$color-maroon-flush: #CF2659;

/* Secondary */
$color-rio-grande: #C1BA85;
$color-pesto: #84752d;
$color-persian-green: #00a8a2;
$color-pine-green: #007772;
$color-fiord: #3E555F;
$color-bermuda-gray: #6f94aa;
$color-pumice: #c6c8c6;
$color-fresh-eggplant: #A5004E;
$color-orient: #00547c;
$color-deep-sea-green: #0D4361;
$color-cornflower-blue: #323e48;
$color-teal: #008188;
$color-abbey: #535355;
$color-wild-sand: #f4f4f4;

/* Support Tints */
$color-spring-wood: #F9F8F3;
$color-snow-drift: #EEF3EB;
$color-alabaster: #F9F9F9;
$color-black-squeeze: #ECF7F8;
$color-alto: #d1d1d1;
$color-off-white: #f2f8fd;

/* Support Dark */
$color-daintree: #022232;
$color-ship-gray: #404042;
$color-orient: #005279;
$color-tundora: #4d4d4d;

/* Background Colours for Alert Banners */
$color-lipstick: #a51e47;
$color-white-smoke: #f5f5f5;

/*
3.  Environment variables
Environment variables. Note some projects put all css into assets,
so the path needs to be able to find the css from there
*/

$theme-name: 'default';
$file-path: "../../../themes/"+$theme-name;
$image-path: $file-path + "/images/";

$base-font-size: 14px;

$font: 'Gustan', Verdana, sans-serif;
$primary-font: $font;
$secondary-font: 'FiraSans', Arial, sans-serif;

/*
4.  Theme colours
Make sure that the colours assigned here are assigned using the
colour variables above
*/

/* Primary colours */
$primary-color: $color-maroon-flush;
$primary-color-hovered: $color-fresh-eggplant;

$secondary-color: $color-deep-cerulean;
$secondary-color-light: $color-cerulean;
$secondary-color-hovered: $color-orient;

/* Secondary Colours */
$color-gold-light: $color-rio-grande;
$color-gold-dark: $color-pesto;
$color-green-light: $color-persian-green;
$color-green-dark: $color-pine-green;
$color-charcoal-grey: $color-fiord;
$color-blue-grey: $color-bermuda-gray;
$color-cool-grey: $color-pumice;
$color-brand-blue: $color-deep-sea-green;
$color-cool-grey: $color-pumice;

/* Support Tints */
$color-gold-light-tint: $color-spring-wood;
$color-gold-dark-tint: $color-snow-drift;
$color-cool-grey-tint: $color-alabaster;
$color-light-blue-tint: $color-black-squeeze;
$color-divider-line: $color-alto;

/* Support - Dark */

$color-dark-blue: $color-daintree;
$color-dark-charcoal: $color-ship-gray;
$color-dark-brand-blue: $color-orient;

/* State colours */
$success-color: $color-pine-green;
$error-color: $color-maroon-flush;
$warning-color: $color-pumice;
$info-color: $color-deep-cerulean;

/* Page Colours */
$body-bg: rgba($color-cool-grey, 0.2);
$font-color: $color-dark-charcoal;


/* Extra Blues */
$color-science-blue: #006dcc;
$color-science-blue-2: #0075db;


/*
5.  Radii and other global css3 style properties
*/

$button-radius: 3px;

/*
6.  Link colours
Make sure that the colours assigned here are assigned using the
colour variables above
*/

// Menu bar
$menu-color: $primary-color;
$menu-link-color: $color-ship-gray;
$menu-link-hover-color: white;
$menu-link-hover-background-color: darken($primary-color, 15%);

// Footer
$footer-color: $color-ship-gray;
$footer-link-color: $color-ship-gray;
$footer-link-hover-color: $menu-link-hover-color;
$footer-link-hover-background-color: $menu-link-hover-background-color;

/*
7.  Tables
*/

$table-header-font-family: $secondary-font;
$table-bgcolor: white;
$table-thead-color: $font-color;
$table-thead-bgcolor: $primary-color;
$table-border-size: 1px;
$table-border-style: solid;
$table-border-color: $color-pumice;
$table-cell-border-size: 1px;
$table-cell-border-color: $color-pumice;
$table-cell-border-style: solid;

/*
8.  Max page widths applied to container elements
*/

$container-width: 1200px;
$max-device-width: 2880px;
$gutter: 30px;

:root {
    --colours-primary-light-blue: #00a7e1;
    --colours-sbn-light-grey: #f3f4f3;
    --colours-primary-pinpoint-red: #cf2659;
    --colours-primary-brand-blue: #0077af;
    --colours-secondary-green-light: #00a8a2;
    --colours-secondary-gold-dark: #84752d;
    --colours-secondary-gold-light: #c7c091;
    --colours-secondary-green-dark: #007772;
    --colours-primary-white: #fff;
    --colours-secondary-blue-grey: #6f94aa;
    --colours-secondary-cool-grey: #c6c8c6;
    --colours-secondary-charcoal-grey: #3e555f;
    --colours-support-gold-light-tint: #f9f8f3;
    --colours-support-gold-dark-tint: #eef3eb;
    --colours-primary-black: #000;
    --colours-support-light-blue-tint: #ecf7f8;
    --colours-support-light-blue: #09A6DF;
    --colours-support-cool-grey-tint: #f9f9f9;
    --colours-support-dark-blue: #022231;
    --colours-support-dark-charcoal: #404042;
    --colours-support-dark-brand-blue: #005279;
    --colours-alto: #d1d1d1
}

/*
	Fixes the scrollbar making content jump issue when an option is selected in nat lang
*/
html {
    overflow-y: scroll;
}

body {
    padding-right: 0px !important;
}

/*
# Colours - primary palette

SectionTemplate: BenManu/StyleGuide/Includes/SGColorPalette

$primary-color - #E20057;
$secondary-color - #0077AF;

Styleguide 1.1
*/

/*
# Colours - secondary palette

SectionTemplate: BenManu/StyleGuide/Includes/SGColorPalette

$color-indian-khaki - #C1BA85;
$color-pesto - #84752d;
$color-persian-green - #00a8a2;
$color-pine-green - #007772;
$color-fiord - #3E555F;
$color-bermuda-gray - #6f94aa;
$color-pumice - #c6c8c6;

Styleguide 1.1.1
*/

/*
## Font Test

<div class="test">
	<h3>Default</h3>

	<p style="font-size:10px">10px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
	<p style="font-size:15px">15px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
	<p style="font-size:20px">20px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
	<p style="font-size:30px">30px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
	<p style="font-size:40px">40px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
</div>

Styleguide 1.2.4
*/
