@import "../utilities/var-breakpoints";
@import "../utilities/var-globals";
@import "../utilities/respond";
@import "../utilities/classes";

.alertbanner {
    position: relative;
    z-index: 999;

    &--error {
        background-color: $color-lipstick;
    }

    &--warning {
        background-color: $color-white-smoke;
    }

    &--info {
        background-color: $color-white-smoke;
    }

    &__inner {
        padding: 15px 15px 20px;

        @include respond(lg) {
            display: flex;
            max-width: 1440px;
            margin: 0 auto;
            padding: 30px 80px;
        }
    }

    &__icon,
    &__close {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__icon {
        width: 40px;
        height: 40px;
        background-image: svg-load("alert-icon-mobile-white.svg");

        @include respond(lg) {
            width: 60px;
            height: 60px;
            margin-right: 20px;
            background-image: svg-load("alert-icon-desktop-white.svg");
        }

        .alertbanner--warning & {
            background-image: svg-load("alert-icon-mobile-red.svg");

            @include respond(lg) {
                background-image: svg-load("alert-icon-desktop-red.svg");
            }
        }

        .alertbanner--info & {
            background-image: svg-load("alert-icon-mobile-blue.svg");

            @include respond(lg) {
                background-image: svg-load("alert-icon-desktop-blue.svg");
            }
        }
    }

    &__close {
        position: absolute;
        top: 23px;
        right: 15px;
        width: 18px;
        height: 18px;
        background-color: transparent;
        background-image: svg-load("close-alert-mobile.svg", stroke=#fff);
        background-size: contain;
        border: none;
        outline: none;

        @include respond(lg) {
            position: relative;
            top: 18px;
            right: auto;
            width: 24px;
            height: 24px;
            margin-left: auto;
            background-image: svg-load("close-alert-desktop.svg", stroke=#fff);
        }

        .alertbanner--warning & {
            background-image: svg-load(
                "close-alert-mobile.svg",
                stroke=$color-maroon-flush
            );

            @include respond(lg) {
                background-image: svg-load(
                    "close-alert-desktop.svg",
                    stroke=$color-maroon-flush
                );
            }
        }

        .alertbanner--info & {
            background-image: svg-load(
                "close-alert-mobile.svg",
                stroke=$color-deep-cerulean
            );

            @include respond(lg) {
                background-image: svg-load(
                    "close-alert-desktop.svg",
                    stroke=$color-deep-cerulean
                );
            }
        }
    }

    &__body {
        @include respond(lg) {
            max-width: calc(100% - 104px); // 104px = 60px + 20px (alert width and margin) + 24px (close icon)
            padding-right: 20px;
        }
    }

    &__title {
        color: white;
        margin: 15px 0 9px;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.34;

        .alertbanner--warning & {
            color: $color-maroon-flush;
        }

        .alertbanner--info & {
            color: $color-deep-cerulean;
        }

        @include respond(lg) {
            margin: 3px 0 7px;
            font-size: 20px;
            line-height: 1.2;
        }
    }

    &__content p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__content * {
        color: white;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 1.429;

        .alertbanner--warning &,
        .alertbanner--info & {
            color: $color-dark-charcoal;
        }
    }

    &__content a {
        color: white;
        text-decoration: underline;

        .alertbanner--warning & {
            color: $color-maroon-flush;
        }

        .alertbanner--info & {
            color: $color-deep-cerulean;
        }
    }

    .nonvisual-indicator {
        @extend .sr-only;
    }
}
